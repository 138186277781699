// FadeInRight Animation
@keyframes fadeInRight {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

// SlideIn Animation
@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    50% {
        opacity: 0.5;
    }
    100% {
         opacity: 1;
        transform: translateY(0);
    }
}