:root {
	--white: hsl(60, 100%, 100%);
	--headline: hsl(205, 84%, 22%);
	--text: hsl(212, 13%, 43%);
	--blue: rgb(59, 168, 252);
	--light-blue: hsl(205, 95%, 92%);
	--light-blue-500: hsl(204deg 100% 96% / 56%);
	--red: hsl(349, 84%, 60%);
	--shadow-color: hsla(0, 0%, 0%, 0.16);
}
