// @import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@1,400;1,500;1,700&family=Inter:wght@400;500;700&family=Nunito:wght@400;500;700&family=Poppins:wght@500;700&display=swap');

:root {
	--ff-cabin: 'Cabin', sans-serif;
	--ff-inter: 'Inter', sans-serif;
	--ff-poppins: 'Poppins', sans-serif;
	--ff-nunito: 'Nunito', sans-serif;
	--fw-400: 400;
	--fw-500: 500;
	--fw-700: 700;
}
