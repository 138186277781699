@use '../util' as *;

.container {
	padding: 0 rem(16);
	max-width: rem(1000);
	margin: 0 auto;
	@include breakpoint(medium) {
		padding: 0 rem(24);
	}
	@include breakpoint(large) {
		padding: 0;
		transform: translate(0, 0);
	}
}
