@use '../util' as *;

.skills {
    padding-top: rem(16);
    padding-bottom: rem(32);
    @include breakpoint(medium){
        padding-top: rem(48);
        padding-bottom: rem(48);
    }
    svg {
        height: 3em; 
        width: 3em;
        fill: var(--red);
    }

    &__tools{
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: repeat(2, 1fr);
        gap: rem(16);
        @include breakpoint(medium){
            grid-template-columns: repeat(4, 1fr);
        }
        li {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: rem(10);
            //margin-bottom: rem(16);
            background-color: var(--headline);
            padding: 1rem;
            @include breakpoint(medium){
                padding: 2rem;
            }
            @include breakpoint(large){
                border: 3px solid var(--headline);
                transition: all 0.3s ease-in-out;
                -webkit-transition: all 0.3s ease-in-out;
                -moz-transition: all 0.3s ease-in-out;
                -ms-transition: all 0.3s ease-in-out;
                -o-transition: all 0.3s ease-in-out;
            }
            &:hover{
                @include breakpoint(large) {
                    background-color: var(--light-blue);
                    transition: all 0.3s ease-in-out;
                    border: 3px solid var(--headline);
                    -webkit-transition: all 0.3s ease-in-out;
                    -moz-transition: all 0.3s ease-in-out;
                    -ms-transition: all 0.3s ease-in-out;
                    -o-transition: all 0.3s ease-in-out;
                }
            }
            span {
                font-size: rem(16);
                font-family: var(--ff-poppins);
                font-weight: var(--fw-700);
                color: var(--red);
            }
        }
    }
}

