:root {
  --ff-cabin: "Cabin", sans-serif;
  --ff-inter: "Inter", sans-serif;
  --ff-poppins: "Poppins", sans-serif;
  --ff-nunito: "Nunito", sans-serif;
  --fw-400: 400;
  --fw-500: 500;
  --fw-700: 700;
}

:root {
  --white: hsl(60, 100%, 100%);
  --headline: hsl(205, 84%, 22%);
  --text: hsl(212, 13%, 43%);
  --blue: rgb(59, 168, 252);
  --light-blue: hsl(205, 95%, 92%);
  --light-blue-500: hsl(204deg 100% 96% / 56%);
  --red: hsl(349, 84%, 60%);
  --shadow-color: hsla(0, 0%, 0%, 0.16);
}

html {
  font-size: 100%;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background: var(--white);
}

body, ul, figure {
  margin: 0;
  padding: 0;
}

li::marker {
  content: none;
}

img {
  max-width: 100%;
}

::selection {
  background-color: var(--light-blue);
}

h1,
h2,
h3 {
  margin: 0 0 2rem;
  line-height: 1.1;
  font-family: var(--ff-inter);
  font-weight: var(--fw-700);
  color: var(--headline);
}

a,
a:visited,
a:active {
  text-decoration: none;
}

h1 {
  font-size: clamp(1.8rem, 2.5vw + 1rem, 4rem);
}

h2 {
  font-size: clamp(1.8rem, 2.5vw + 1rem, 3rem);
}

h3 {
  font-size: clamp(1.5rem, 2.5vw + 1rem, 2rem);
}

p {
  font-size: clamp(1rem, calc(1rem + 0.540vw), 1.75rem);
  font-family: var(--ff-poppins);
  line-height: 1.2;
  color: var(--text);
  margin-top: 0;
}

.container {
  padding: 0 1rem;
  max-width: 62.5rem;
  margin: 0 auto;
}
@media (min-width: 40em) {
  .container {
    padding: 0 1.5rem;
  }
}
@media (min-width: 71.875em) {
  .container {
    padding: 0;
    transform: translate(0, 0);
  }
}

.plus-btn-pos {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  position: fixed;
  width: 100%;
  z-index: 10;
}
@media (min-width: 40em) {
  .plus-btn-pos {
    display: none;
    position: relative;
  }
}

.plus-btn {
  width: 2.375rem;
  height: 2.375rem;
  border-radius: 100%;
  position: relative;
  background: var(--blue);
  box-shadow: 0 2px 5px 0 var(--shadow-color), 0 2px 10px 0 var(--shadow-color);
  z-index: 2;
}
.plus-btn.active {
  background: var(--red);
}
.plus-btn.active .r1, .plus-btn.active .r2 {
  background: var(--white);
}
.plus-btn.active .r1 {
  transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  -moz-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  -ms-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  -o-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
}
.plus-btn.active .r2 {
  transform: translateX(-50%) translateY(-50%) rotate(-135deg);
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(-135deg);
  -moz-transform: translateX(-50%) translateY(-50%) rotate(-135deg);
  -ms-transform: translateX(-50%) translateY(-50%) rotate(-135deg);
  -o-transform: translateX(-50%) translateY(-50%) rotate(-135deg);
}
.plus-btn .r1,
.plus-btn .r2 {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1.375rem;
  height: 0.125rem;
  background: var(--white);
  transition: transform 0.3s;
  transition-delay: 0.4s;
  -webkit-transition: transform 0.3s;
  -moz-transition: transform 0.3s;
  -ms-transition: transform 0.3s;
  -o-transition: transform 0.3s;
}
.plus-btn .r1 {
  transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  -moz-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  -ms-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  -o-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
}
.plus-btn .r2 {
  transform: translateX(-50%) translateY(-50%) rotate(-180deg);
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(-180deg);
  -moz-transform: translateX(-50%) translateY(-50%) rotate(-180deg);
  -ms-transform: translateX(-50%) translateY(-50%) rotate(-180deg);
  -o-transform: translateX(-50%) translateY(-50%) rotate(-180deg);
}
.plus-btn:active {
  box-shadow: none;
}

.title-line {
  position: relative;
  display: inline-block;
}
.title-line::after {
  content: "";
  position: absolute;
  background-color: var(--light-blue);
  -webkit-box-shadow: 0 0 8px var(--light-blue);
  box-shadow: 0 0 8px var(--light-blue);
  height: 0.625rem;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
}
@media (min-width: 40em) {
  .title-line::after {
    height: 1rem;
  }
}

.card {
  background-color: var(--light-blue-500);
  border-radius: 0.5rem;
  margin-bottom: 2rem;
  padding: 1rem;
}
@media (min-width: 40em) {
  .card {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-items: center;
  }
}
.card h3 {
  margin-bottom: 1rem;
}
@media (min-width: 40em) {
  .card__img {
    width: 40%;
  }
}
.card__img figure {
  width: 100%;
  height: 20rem;
  margin-bottom: 1.5rem;
}
@media (min-width: 40em) {
  .card__img figure {
    margin-bottom: 0;
  }
}
.card__img figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}
@media (min-width: 40em) {
  .card__description {
    width: 60%;
  }
}
.card__description .btns {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}
.card__description .btns a {
  background-color: var(--blue);
  border-radius: 20px;
  color: var(--white);
  display: inline-block;
  font-family: var(--ff-poppins);
  font-size: 1rem;
  padding: 0.25rem 1rem;
  transition: all 0.3s ease-in-out;
}
.card__description .btns a:hover {
  background-color: var(--red);
  transition: all 0.3s ease-in-out;
}
@media (min-width: 40em) {
  .card__description .btns {
    justify-content: left;
  }
}
.card__description ul {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}
.card__description ul li {
  font-size: 0.8rem;
  text-transform: uppercase;
  font-family: var(--ff-inter);
  font-weight: 600;
  color: var(--red);
}
.card__description p {
  font-size: 1rem;
  font-weight: 400;
  font-family: var(--ff-nunito);
}

@media (min-width: 40em) {
  .fixed {
    position: fixed;
    width: 100%;
    background-color: var(--light-blue);
    transition: all 0.45s;
    -webkit-transition: all 0.45s;
    -moz-transition: all 0.45s;
    -ms-transition: all 0.45s;
    -o-transition: all 0.45s;
    z-index: 10;
    animation: slideIn 0.55s ease-in-out 0.1s;
    box-shadow: 0px 0px 10px 0px var(--shadow-color);
  }
  .fixed .navbar__wrapper {
    transition: all 0.45s;
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.navbar__wrapper {
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 2rem;
  padding-bottom: 2rem;
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1;
  transform: translateY(-100%);
  transition: all 0.45s;
  background-color: var(--light-blue);
}
@media (max-width: 39.9375em) {
  .navbar__wrapper {
    visibility: hidden;
  }
}
@media (min-width: 40em) {
  .navbar__wrapper {
    flex-direction: initial;
    justify-content: space-between;
    align-items: center;
    position: relative;
    transform: translateY(0);
    background-color: initial;
  }
}
.navbar__wrapper.open {
  transform: translateY(0);
  transition: all 0.45s;
}
@media (max-width: 39.9375em) {
  .navbar__wrapper.open {
    visibility: visible;
  }
}
.navbar a {
  position: relative;
  display: inline-block;
}
.navbar ul {
  display: flex;
  gap: 1.5625rem;
}
.navbar ul li {
  position: relative;
}
@media (min-width: 40em) {
  .navbar .plus-btn-pos {
    display: none;
  }
}
.navbar__brand {
  font-family: var(--ff-cabin);
  font-size: 1.875rem;
  letter-spacing: 0.3125rem;
  font-weight: 700;
  font-style: italic;
  color: var(--headline);
  transition: all 0.2s ease-in-out;
}
.navbar__brand:hover, .navbar__brand:focus {
  color: var(--red);
  transform: scale(1.2);
  transition: all 0.2s ease-in-out;
}
.navbar__links {
  flex-direction: column;
  gap: 1.25rem;
  transition: all 0.2s ease-in-out;
}
.navbar__links a {
  font-family: var(--ff-cabin);
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--headline);
  position: relative;
  transition: all 0.2s ease-in-out;
  display: inline-block;
}
.navbar__links a:hover {
  color: var(--red);
  transform: scale(1.15);
  transition: all 0.2s ease-in-out;
}
@media (min-width: 40em) {
  .navbar__links {
    flex-direction: initial;
    gap: 0.125rem;
  }
}
.navbar__social-links svg {
  fill: var(--headline);
  transition: all 0.2s ease-in-out;
  height: 1.8em;
  width: 1.8em;
}
.navbar__social-links svg:hover {
  fill: var(--red);
  transform: scale(1.2);
  transition: all 0.2s ease-in-out;
}

.hero__wrapper {
  display: flex;
  flex-direction: column-reverse;
  align-content: center;
  align-items: center;
  padding-top: 4rem;
  padding-bottom: 1rem;
  gap: 2rem;
}
@media (min-width: 40em) {
  .hero__wrapper {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(2, 1fr);
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
    padding-bottom: 4rem;
  }
}
.hero__content h1 {
  color: var(--headline);
  margin-bottom: 1rem;
}
@media (min-width: 40em) {
  .hero__content h1 {
    margin-bottom: 2rem;
  }
}
.hero__content p {
  line-height: 1.5;
  letter-spacing: 0.0625rem;
  color: var(--headline);
  margin-bottom: 0;
}
.hero__image img {
  width: min(45vw, 22.5em);
}
@media (min-width: 40em) {
  .hero__image img {
    width: min(35vw, 22.5em);
  }
}

.projects {
  padding: 1rem 0;
}
@media (min-width: 40em) {
  .projects {
    padding: 3rem 0;
  }
}
.projects h2 {
  color: var(--headline);
}

.about {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
@media (min-width: 40em) {
  .about {
    padding-bottom: 3rem;
  }
}
.about h2 {
  margin-bottom: 2rem;
}
.about__wrapper {
  display: flex;
  gap: 1.5rem;
  flex-direction: column-reverse;
}
@media (min-width: 40em) {
  .about__wrapper {
    flex-direction: row;
  }
}
.about__bio p {
  font-size: clamp(1rem, calc(1rem + 0.540vw), 1.4rem);
}
.about__bio p:last-child {
  margin-bottom: 0;
}
@media (min-width: 40em) {
  .about__bio {
    width: 69%;
  }
}
@media (min-width: 40em) {
  .about__image {
    width: 29%;
  }
}
.about__image img {
  border-radius: 15px;
}

.skills {
  padding-top: 1rem;
  padding-bottom: 2rem;
}
@media (min-width: 40em) {
  .skills {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
.skills svg {
  height: 3em;
  width: 3em;
  fill: var(--red);
}
.skills__tools {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}
@media (min-width: 40em) {
  .skills__tools {
    grid-template-columns: repeat(4, 1fr);
  }
}
.skills__tools li {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.625rem;
  background-color: var(--headline);
  padding: 1rem;
}
@media (min-width: 40em) {
  .skills__tools li {
    padding: 2rem;
  }
}
@media (min-width: 71.875em) {
  .skills__tools li {
    border: 3px solid var(--headline);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
  }
}
@media (min-width: 71.875em) {
  .skills__tools li:hover {
    background-color: var(--light-blue);
    transition: all 0.3s ease-in-out;
    border: 3px solid var(--headline);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
  }
}
.skills__tools li span {
  font-size: 1rem;
  font-family: var(--ff-poppins);
  font-weight: var(--fw-700);
  color: var(--red);
}

.contact {
  padding-top: 2rem;
  padding-bottom: 2.625rem;
  background-color: var(--light-blue-500);
}
@media (min-width: 40em) {
  .contact {
    padding-top: 4rem;
    padding-bottom: 5rem;
  }
}
.contact svg {
  height: 1.8em;
  width: 1.8em;
  fill: var(--headline);
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.contact p {
  margin-bottom: 3rem;
}
@media (min-width: 40em) {
  .contact p {
    margin-bottom: 3.75rem;
  }
}
.contact a:hover svg {
  fill: var(--red);
  transform: scale(1.2);
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}
.contact__social-icons {
  display: flex;
  gap: 1rem;
}

.footer {
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
  background-color: var(--headline);
}
.footer p {
  margin-bottom: 0;
  color: var(--white);
}