@use '../util' as *;

.hero {
    &__wrapper {
        display: flex;
        flex-direction: column-reverse;
        align-content: center;
        align-items: center;
        padding-top: rem(64);
        padding-bottom: rem(16);
        gap: rem(32);
        @include breakpoint(medium) {
            display: grid;
            grid-template-rows: 1fr;
            grid-template-columns: repeat(2, 1fr);
            padding-top: rem(100);
            padding-bottom: rem(100);
            padding-bottom: rem(64);
        }
    }
    &__content {
        h1 {
            color: var(--headline);
            margin-bottom: 1rem;
            @include breakpoint(medium){
                margin-bottom: 2rem;
            }
        }
        p {
            line-height: 1.5;
            letter-spacing: rem(1);
            color: var(--headline);
            margin-bottom: 0;
        }
    }

    &__image {
        img {
            width: min(45vw, 22.5em);
            @include breakpoint(medium){
                width: min(35vw, 22.5em);
            }
        }
    }
}