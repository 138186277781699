
@use '../util' as *;

.card {
    background-color: var(--light-blue-500);
    border-radius: 0.5rem;
    margin-bottom: 2rem;
    padding: 1rem;
    @include breakpoint(medium){
        display: flex;
        flex-direction: row;
        gap: 2rem;
        align-items: center;
    }
    h3 {
        margin-bottom: 1rem;
    }
    &__img {
        @include breakpoint(medium){
            width: 40%;
        }
        figure {
            width: 100%;
            height: 20rem;
            margin-bottom: 1.5rem;
            @include breakpoint(medium){
                margin-bottom: 0;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                border-radius: 0.5rem;
                box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
            }
        }
    }
    &__description {
        @include breakpoint(medium){
            width: 60%;
        }
        .btns {
            display: flex;
            gap: 1rem;
            justify-content: space-between;
            a {
                background-color: var(--blue);
                border-radius: 20px;
                color: var(--white);
                display: inline-block;
                font-family: var(--ff-poppins);
                font-size: 1rem;
                padding: 0.25rem 1rem;
                transition: all .3s ease-in-out;
                &:hover {
                    background-color: var(--red);
                    transition: all .3s ease-in-out;
                }
            }
            @include breakpoint(medium){
                justify-content: left;
            }
        }
        ul {
            display: flex;
            gap: 1rem;
            margin-bottom: 1rem;
            li {
                font-size: .8rem;
                text-transform: uppercase;
                font-family: var(--ff-inter);
                font-weight: 600;
                color: var(--red);
            }
        }
        p {
            font-size: 1rem;
            font-weight: 400;
            font-family: var(--ff-nunito);
        }
    }
}